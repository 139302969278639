import React, { useEffect, useState } from "react";
import axios from 'axios';
import logoMacro from '../../assets/img/png/LogoMacro.png'; 
import excelIcon from '../../assets/img/png/excel.png';
import wordIcon from '../../assets/img/png/word.png';
import pdfIcon from '../../assets/img/png/pdf.png';
import './Reports.css';
import { decryptData } from "utils/cript";
import CadForm from "components/CadForm";
import { sendContactForm } from "services/contactForm";
import { ContactFormInterface } from "interfaces/contactFormInterface";
import Modal from "components/Modal";

const Sdr = () => {
    
    const handleDownload = async () => {

        try {
            // Fazendo a solicitação para a URL do arquivo
            const response = await fetch(decryptData('U2FsdGVkX1/fu5cWqsfiUCCen3RSlftSBLJPHRdj3mfe58s1fQBwOWkPR6+vdl8k5lLT4RK8iOpjq1RTk1I70A98KvljsDhSCxelHj48ysg='), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });
    
            // Verifica se a resposta foi bem-sucedida
            if (!response.ok) {
                throw new Error('Erro ao baixar o arquivo.');
            }
    
            const blob = await response.blob(); // Cria um blob com o conteúdo do arquivo
            const link = document.createElement('a'); // Cria um link temporário
            link.href = window.URL.createObjectURL(blob); // Cria um URL para o blob
            link.download = 'macroreportvaluation.pdf'; // Define o nome do arquivo a ser baixado
            document.body.appendChild(link); // Adiciona o link ao DOM
            link.click(); // Simula o clique no link para forçar o download
            document.body.removeChild(link); // Remove o link do DOM
            window.URL.revokeObjectURL(link.href); // Libera a URL do blob
        } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
        }
    };

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    
    const [loading, setLoading] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        message: ''
    });

    useEffect(() => {
        setEmail('');
        setName('');
        setPhone('');
        setError('');
    }, []); 
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true); // Inicia o carregamento

        try {
            const newContact: ContactFormInterface = {
                fullName: name,
                email: email,
                phone: phone,
                origin: "SDR",
                accessLevel: "L",
                lead: true
            };
            await sendContactForm(newContact);

            // Se o envio for bem-sucedido
            setModalContent({
                title: '',
                message: 'Agradecemos seu cadastro, o relatório será baixado em instantes.'
            });
            
            setModalVisible(true);

            setEmail('');
            setName('');
            setPhone('');
            setError('');

            await handleDownload()

        } catch (error) {
            
            await handleDownload()

            setEmail('');
            setName('');
            setPhone('');
            setError('');
            
            setLoading(false);
        } finally {
            setError('');
            setLoading(false);
        }
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    return (
        <div className="sdr-base">
            <div className="sdr-reports-container">

                <div className="base-modal">
                    <div className="base-modal-content">
                        <img src={logoMacro} alt="Logo Macro" width={150} style={{ marginTop: 20, marginBottom: 30 }} />
                        

                        <p style={{ color: '#fff', marginBottom: 30 }}>
                            Report Valuation
                        </p>

                        <form onSubmit={handleSubmit}>
                        <div>
                            <input 
                                type="text" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                required 
                                placeholder={'Nome'}
                                style={{ width: 270, marginBottom: -1, height: 15 }}
                            />
                        </div>
                        <div>
                            <input 
                                type="phone" 
                                value={phone} 
                                placeholder={'Telefone'}
                                onChange={(e) => setPhone(e.target.value)} 
                                required 
                                style={{ width: 274, height: 17}}
                            />
                        </div>
                        <div>
                            <input 
                                type="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                required 
                                placeholder={'e-mail'}
                                style={{ width: 274, height: 17}}
                            />
                        </div>

                        {error && <p className="error">{error}</p>}

                        <div style={{ marginTop: 30}}>
                            <button style={{ width: '90%', marginLeft: '5%' }} type="submit">{loading ? <div className="spinner"></div> : 'Realizar Download'} </button>
                        </div>
                                
                    </form>
                </div>

                    <Modal
                        isOpen={modalVisible}
                        onClose={handleModalClose}
                        title={modalContent.title}
                    >
                        <p>{modalContent.message}</p>
                    </Modal>
                </div>


            </div>
        </div>
    );
};

export default Sdr;
