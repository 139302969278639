import React from 'react';
import MacroCommunity from '../../assets/img/png/macro_comunidade.png';

interface CommunitySlideProps {
  setIsModalCadOpen: (arg: boolean) => void; // Define a tipagem da prop
}

const CommunitySlide: React.FC<CommunitySlideProps> = ({ setIsModalCadOpen }) => {

  return (
    <div
      style={{
        position: 'relative', // Permite posicionar o overlay
        height: '100%',
        backgroundImage: `url(${MacroCommunity})`, // Imagem de fundo
        backgroundSize: 'cover', // Faz a imagem de fundo cobrir todo o slide
        backgroundPosition: 'center', // Centraliza a imagem de fundo
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {/* Overlay preto */}
      <div
        style={{
          position: 'absolute', // Posiciona o overlay sobre a imagem
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // Cor preta com 50% de opacidade
          zIndex: 1, // Z-index inferior ao texto
        }}
      ></div>

      <h1 style={{ zIndex: 2 }}>Comunidade Macro</h1>
      <p style={{ zIndex: 2 }}>Venha fazer parte da nossa comunidade de traders!</p>
      <button style={{ zIndex: 2, marginTop: 28 }} onClick={() => setIsModalCadOpen(true)}>Saiba mais</button>
    </div>
  );
};

export default CommunitySlide;
