// LoginModal.tsx
import React, { useEffect, useState } from 'react';
import './CadForm.css';
import logo from '../../assets/img/png/LogoMacro.png';
import api from 'services/api';
import { ContactFormInterface } from 'interfaces/contactFormInterface';
import { sendContactForm } from 'services/contactForm';
import Modal from 'components/Modal';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  origin: string
}

const CadForm: React.FC<LoginModalProps> = ({ isOpen, onClose, origin }) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    
    const [loading, setLoading] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        message: ''
    });

    useEffect(() => {
        if (isOpen) {
        setEmail('');
        setName('');
        setPhone('');
        setError('');
        }
    }, [isOpen]); 
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true); // Inicia o carregamento

        try {
            const newContact: ContactFormInterface = {
                fullName: name,
                email: email,
                phone: phone,
                origin: origin,
                accessLevel: "L",
                lead: true
            };
            await sendContactForm(newContact);

            // Se o envio for bem-sucedido
            setModalContent({
                title: '',
                message: 'Agradecemos seu cadastro, em breve nossa equipe entrará em contato.'
            });
            
            setModalVisible(true);

            // Limpa o formulário após o envio
            setEmail('');
            setName('');
            setPhone('');
            setError('');

        } catch (error) {
            // Se houver uma falha
            setModalContent({
                title: 'Erro!',
                message: 'O e-mail informado já está cadastrado!'
            });
            setError('S');
            setModalVisible(true);
        } finally {
            setError('');
            setLoading(false); // Finaliza o carregamento, seja sucesso ou erro
        }
    };

    const handleModalClose = () => {
        setModalVisible(false);
        if (error === ''){
            onClose()
        }
    };

  if (!isOpen) return null;

  return (
    <div className="base-modal">
        <div className="base-modal-content">
            <img src={logo} alt="Logo Macro" width={150} style={{ marginTop: 20, marginBottom: 30 }} />
            

            <p style={{ color: '#fff', marginBottom: 40 }}>
                { origin === 'SDR' ? 'Baixar Relatório' : 'Solicitar Acesso' }
            </p>

            <form onSubmit={handleSubmit}>
            <div>
                <input 
                    type="text" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                    placeholder={'Nome'}
                    style={{ width: '93%'}}
                />
            </div>
            <div>
                <input 
                    type="phone" 
                    value={phone} 
                    placeholder={'Telefone'}
                    onChange={(e) => setPhone(e.target.value)} 
                    required 
                    style={{ width: '93%'}}
                />
            </div>
            <div>
                <input 
                    type="text" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                    placeholder={'e-mail'}
                    style={{ width: '92%'}}
                />
            </div>

            {error && <p className="error">{error}</p>}

            <div className="button-container" style={{ marginTop: 30, display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                <button 
                    style={{ marginRight: 20, width: 120, height: 40 }} 
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancelar
                </button>

                <button 
                    style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 120, fontSize: 15 }}
                    type="submit"
                >
                    {loading ? <div className="spinner"></div> : 'Confirmar'}
                </button>
            </div>
                    
        </form>
      </div>

        <Modal
            isOpen={modalVisible}
            onClose={handleModalClose}
            title={modalContent.title}
        >
            <p>{modalContent.message}</p>
        </Modal>
    </div>
  );
};

export default CadForm;
