import React, { useState } from 'react';
import './ContactForm.css'; // Importa o CSS para estilização
import { sendContactForm } from 'services/contactForm';
import { ContactFormInterface } from 'interfaces/contactFormInterface';
import Modal from 'components/Modal';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: ''
    });

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        message: ''
    });

    const [loading, setLoading] = useState(false); // Novo estado para carregar

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true); // Inicia o carregamento

        try {
            const newContact: ContactFormInterface = {
                fullName: formData.name,
                email: formData.email,
                phone: formData.phone,
                origin: "Contato",
                accessLevel: "L",
                lead: true
            };
            await sendContactForm(newContact);

            // Se o envio for bem-sucedido
            setModalContent({
                title: '',
                message: 'Agradecemos seu cadastro, em breve nossa equipe entrará em contato.'
            });
            
            setModalVisible(true);

            // Limpa o formulário após o envio
            setFormData({
                name: '',
                phone: '',
                email: ''
            });

        } catch (error) {
            // Se houver uma falha
            setModalContent({
                title: 'Erro!',
                message: 'O e-mail informado já está cadastrado!'
            });
            setModalVisible(true);
        } finally {
            setLoading(false); // Finaliza o carregamento, seja sucesso ou erro
        }
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    return (
        <div className="contact-container">
            <div className="form-section">
                <form className="contact-form" onSubmit={handleSubmit}>
                    <h2 style={{ color: '#323e56', marginBottom: 40 }}>Fale com um assessor</h2>
                    <div className="form-group">
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange} 
                            placeholder='Nome'
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <input 
                            type="tel" 
                            id="phone" 
                            name="phone" 
                            placeholder='Telefone'
                            value={formData.phone} 
                            onChange={handleChange} 
                            required 
                            pattern="\d*" // Permite apenas números
                            title="Por favor, insira um número de telefone válido" // Mensagem de erro personalizada
                        />
                    </div>
                    <div className="form-group">
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            placeholder='e-mail'
                            value={formData.email} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                    <div className="form-group-button">
                        <div style={{ marginBottom: 30 }}>
                            <button 
                                type="submit" 
                                className="submit-button"
                                disabled={loading} // Desabilita o botão durante o carregamento
                            >
                                {loading ? <div className="spinner"></div> : 'Enviar'} {/* Exibe o spinner ou o texto */}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <Modal
                isOpen={modalVisible}
                onClose={handleModalClose}
                title={modalContent.title}
            >
                <p>{modalContent.message}</p>
            </Modal>
        </div>
    );
};

export default ContactForm;
