import React, { useRef, useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import './App.css';
import AIChat from './components/AIChat';
import Footer from './components/Footer/Footer';
import Products from './components/Products';
import ContactForm from './components/ContactForm';
import About from './components/About';
import AboutEqi from './components/AboutEqi';
import MainCarousel from './components/MainCarousel';

// Adicionando novos componentes para as rotas
import ProductsInfo from './components/ProductsInfo'; // Componente para /private
import Reports from './components/Reports';
import ReportController from './components/Admin/ReportController';
import Main from 'components/Admin/Main';
import Sdr from 'components/Srd';

const App: React.FC = () => {

    const productsRef = useRef<HTMLDivElement>(null); // Reference for Products
    const contactRef = useRef<HTMLDivElement>(null); // Reference for Contact Form
    const macroRef = useRef<HTMLDivElement>(null); // Reference for Macro

    // Scroll to Products
    const scrollToProducts = () => {
        if (productsRef.current) {
            productsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Scroll to Contact
    const scrollToContact = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Scroll to Macro
    const scrollToMacro = () => {
        if (macroRef.current) {
            macroRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Verifica o caminho atual da URL e renderiza o componente apropriado
    const pathname = window.location.pathname;

    const renderPage = () => {
        switch (pathname) {
            case '/private':
                return <ProductsInfo pageCode={'dc82a4d3-64fb-4259-94c7-ae36666e6b3b'} />; // Componente para a rota /private
            case '/global':
                return <ProductsInfo pageCode={'fd0bbbe0-68a9-46cc-bef0-c70e663e026b'} />;  // Componente para a rota /global
            case '/investimentos':
                return <ProductsInfo pageCode={'b9147c8-0ce3-4d92-a044-f798dbe8eab9'} />; // Componente para a rota /investimentos
            case '/trader':
                return <ProductsInfo pageCode={'8c3a866a-bf70-4d2b-9ac5-899eb7c579b2'} />;  // Componente para a rota /trader
            case '/agro':
                return <ProductsInfo pageCode={'d991d327-b86f-42c3-ab38-b41ad8745403'} />;   // Componente para a rota /macro
            case '/macro':
                return <ProductsInfo pageCode={'cdfef5e5-3ea8-44e0-9571-4b9d0d6511b2'} />;   // Componente para a rota /macro   
            case '/reports':
                return <Reports />;
            case '/adm/main':
                return <Main />;                
            case '/sdr/report':
                return <Sdr />;  

            /*
            case '/':
                return <Sdr />;  
            */
           
            default:
                // Página padrão se a rota não for uma das especificadas
                return (
                    <>
                        <MainCarousel />

                        <div ref={productsRef}> {/* Reference to Products component */}
                            <Products />
                        </div>

                        <div ref={macroRef}> {/* Reference to About component */}
                            <About />
                        </div>

                        <AboutEqi />
                    </>
                );
        }
    };

    // Conditionally render components based on pathname
    const isAdminPage = pathname.startsWith('/adm');
    const isSrdPage = pathname.startsWith('/sdr');

    return (
        <>
            <div className="App">

                
                {!isAdminPage && !isSrdPage && (

                    <Navbar
                        scrollToProducts={scrollToProducts}
                        scrollToContact={scrollToContact}
                        scrollToMacro={scrollToMacro}
                    />

                )}

                {renderPage()} {/* Renderiza o componente baseado na URL */}

                {!isAdminPage && !isSrdPage && (
                    <div ref={contactRef}> {/* Reference to Contact Form */}
                        <ContactForm />
                    </div>
                )}

                {!isAdminPage && !isSrdPage && <Footer /> }

            </div>
            {!isAdminPage && !isSrdPage && <AIChat /> }
        </>
    );
};

export default App;
